import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import MagazineTeaser from '@objects/magazineTeaser'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CustomSelect from '@objects/customSelect'

import Paginator from '@objects/pagination'
import PaginationItem from '@objects/paginationItem'

const useStyles = makeStyles((theme) => ({
  filteredMagazineTeaserList: {
    margin: theme.spacing(-2.5),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-4),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  categorySelectorWrapper: {
    height: '46px',
    display: 'block',
    marginBottom: '42px',
    marginLeft: '-6px',
    marginRight: '-6px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  categorySelector: {
    width: '70%',
    height: '46px',
  },
  categorySelectorFixed: {
    top: 0,
    left: 0,
    position: 'fixed',
    zIndex: 3,
  },
  categoryTabs: {
    display: 'none',
    width: '100%',
    marginBottom: '42px',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  tab: {
    flexGrow: 1,
    textTransform: 'none',
    borderBottom: `${theme.palette.grey.light} solid 2px`,
  },
  teaser: {
    padding: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      flex: '0 0 auto',
      width: '50%',
      [theme.breakpoints.up('lg')]: {
        width: `calc(50% - ${theme.spacing(15)}px)`,
      },
    },
  },
  pagination: {
    width: '100%',
    marginTop: '36px',
  },
}))

function FilteredMagazineTeaserList({ className, articles, categories }) {
  const classes = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [displayIndex, setDisplayIndex] = useState(0)
  const [displayedArticles, setDisplayedArticles] = useState(articles)
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const [isSelectorFixed, setIsSelectorFixed] = useState(false)
  const categorySelectorRef = useRef(null)
  const categorySelectorWrapperRef = useRef(null)
  const limit = 12
  const location = useLocation()

  useLayoutEffect(() => {
    const hash = queryString.parse(location.hash)
    if (hash['tab-item']) {
      setSelectedCategory(
        parseInt(hash['tab-item']),
        document.getElementById('filteredMagazineTeaserList').scrollIntoView()
      )
    }
  }, [location.hash])

  useEffect(() => {
    if (!isMedium) {
      document.addEventListener('scroll', toggleFixedSelector)
      return () => {
        document.removeEventListener('scroll', toggleFixedSelector)
      }
    }
  }, [isMedium])

  useEffect(() => {
    if (selectedCategory === 0) {
      setDisplayedArticles(articles)
    } else {
      setDisplayedArticles(
        articles.filter((article) => {
          return article.magazineCategory === categories[selectedCategory - 1]
        })
      )
    }
    setDisplayIndex(0)
  }, [selectedCategory])

  function toggleFixedSelector() {
    const boundingWrapperClipRect =
      categorySelectorWrapperRef.current.getBoundingClientRect()
    if (boundingWrapperClipRect.top <= 0) {
      setIsSelectorFixed(true)
    } else {
      setIsSelectorFixed(false)
    }
  }

  function renderArticles() {
    let renderItems = []
    const currentLimit = Math.min(
      displayIndex + limit,
      displayedArticles.length
    )
    for (let idx = displayIndex; idx < currentLimit; idx++) {
      const article = displayedArticles[idx]
      renderItems.push(
        <MagazineTeaser
          key={idx}
          className={classes.teaser}
          type={'rowSmall'}
          imageRatio={'square'}
          slug={article.slug}
          image={article.image}
          imageAlt={article.imageAlt}
          subline={article.subline}
          headline={article.headline}
          copy={isMedium ? article.copy : null}
          url={article.url}
        />
      )
    }
    return renderItems
  }

  function onPaginationChange(event, page) {
    setDisplayIndex(limit * (page - 1))
  }

  function renderPagination(item) {
    return <PaginationItem {...item} />
  }

  const handleTabChange = (event, newValue) => {
    setSelectedCategory(newValue)
  }

  function renderTabs() {
    let tabs = [
      <Tab
        key="tab-0"
        id="tab-item=0"
        classes={{ root: classes.tab }}
        label={intl.formatMessage({ id: 'magazine.filters.all' })}
      />,
    ]
    categories.forEach((cat, index) => {
      tabs.push(
        <Tab
          key={`tab-${index + 1}`}
          id={`tab-item=${index + 1}`}
          classes={{ root: classes.tab }}
          label={cat}
        />
      )
    })
    return tabs
  }

  function getCategoriesForSelector() {
    return [
      { value: 0, text: intl.formatMessage({ id: 'magazine.filters.all' }) },
      ...categories.map((cat, index) => {
        return {
          value: index + 1,
          text: cat,
        }
      }),
    ]
  }

  return (
    <div
      className={classes.filteredMagazineTeaserList}
      id="filteredMagazineTeaserList"
    >
      <div
        className={classes.categorySelectorWrapper}
        ref={categorySelectorWrapperRef}
      >
        <CustomSelect
          customRef={categorySelectorRef}
          className={clsx(classes.categorySelector, {
            [classes.categorySelectorFixed]: isSelectorFixed,
          })}
          onOptionSelected={(option) => {
            setSelectedCategory(option)
          }}
          options={getCategoriesForSelector()}
          defaultValue={selectedCategory}
          selectWidth={'100%'}
          selectHeight={'46px'}
          popoverVerticalOrigin={-46}
          type={'orange'}
        />
      </div>
      <Tabs
        className={classes.categoryTabs}
        value={selectedCategory}
        onChange={handleTabChange}
        aria-label="filters"
        variant="scrollable"
      >
        {renderTabs()}
      </Tabs>
      {renderArticles()}
      {displayedArticles.length > limit && (
        <Paginator
          className={classes.pagination}
          count={Math.ceil(displayedArticles.length / limit)}
          renderItem={renderPagination}
          onChange={onPaginationChange}
        />
      )}
    </div>
  )
}

FilteredMagazineTeaserList.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      image: PropTypes.object,
      imageAlt: PropTypes.string,
      subline: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string,
      magazineCategory: PropTypes.string,
    })
  ),
}

FilteredMagazineTeaserList.defaultProps = {
  layout: 'default',
}

export default FilteredMagazineTeaserList
