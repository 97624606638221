import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Button from '@objects/button'
import MagazineTeaser from '@objects/magazineTeaser'
import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.h2,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(15),
      textAlign: 'center',
    },
  },
  gridContainer: {
    margin: theme.spacing(-2.5),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-4),
      display: 'flex',
      flexDirection: (props) =>
        props.layout === 'heros' ? 'row' : 'row-reverse',
    },
  },
  gridItem: {
    padding: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      flex: '0 1 auto',
      width: '50%',
    },
    '& + &': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(11),
      },
    },
  },
  heros: {
    [theme.breakpoints.up('lg')]: {
      flex: '1 0 auto',
      boxSizing: 'initial',
      paddingRight: theme.spacing(11),
    },
  },
  teaser: {
    '& + &': {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(8),
      },
    },
  },
  button: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },
}))

function MagazineTeaserGrid({
  className,
  headline,
  articles,
  layout,
  button,
  customClasses,
}) {
  const classes = useStyles({ layout })
  const theme = useTheme()

  const isMedium = useMediaQuery(theme.breakpoints.up('md'))

  const hero = articles[0]

  function renderArticles() {
    return articles.slice(1).map((article, i) => {
      return (
        <MagazineTeaser
          key={i}
          className={classes.teaser}
          type={layout === 'heros' ? 'row' : isMedium ? 'col' : 'row'}
          imageRatio={layout === 'default' && isMedium ? 'lsSmall' : 'square'}
          slug={article.slug}
          image={article.image}
          imageAlt={article.imageAlt}
          focalPoint={article.focalPoint}
          subline={article.subline}
          headline={article.headline}
          copy={isMedium ? article.copy : null}
          readingTime={
            layout === 'default' && isMedium ? article.readingTime : null
          }
          createdAt={
            layout === 'default' && isMedium ? article.createdAt : null
          }
          url={article.url}
        />
      )
    })
  }

  return (
    <>
      <Headline
        level={2}
        className={clsx(classes.headline, customClasses?.headline)}
      >
        {headline}
      </Headline>
      <div className={classes.gridContainer}>
        <div
          className={clsx(classes.gridItem, {
            [classes.heros]: layout === 'heros',
          })}
        >
          {hero && (
            <MagazineTeaser
              className={classes.teaser}
              type={'col'}
              imageRatio={
                !isMedium
                  ? 'lsMedium'
                  : layout === 'heros'
                  ? 'lsLarge'
                  : 'square'
              }
              {...hero}
            />
          )}
        </div>
        <div className={classes.gridItem}>{renderArticles()}</div>
      </div>
      {button && (
        <div className={classes.button}>
          <Button to={button.to} color="red">
            {button.label}
          </Button>
        </div>
      )}
    </>
  )
}

MagazineTeaserGrid.propTypes = {
  className: PropTypes.string,
  customClasses: PropTypes.shape({
    headline: PropTypes.string,
  }),
  headline: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      image: PropTypes.object,
      focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
      imageAlt: PropTypes.string,
      subline: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string,
      readingTime: PropTypes.number,
      createdAt: PropTypes.string,
    })
  ),
  layout: PropTypes.oneOf(['default', 'heros']),
  button: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
}

MagazineTeaserGrid.defaultProps = {
  layout: 'default',
}

export default MagazineTeaserGrid
