import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import useDateFns from '@hooks/useDateFns'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'

import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  magazineTeaserRoot: {
    display: 'block',
    '&:hover $image, &:focus $image': {
      top: theme.spacing(-1),
      left: theme.spacing(-1),
      bottom: theme.spacing(-1),
      right: theme.spacing(-1),
      boxShadow: theme.customShadow.topicTeaserHover,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    '& $imageWrapper': {
      width: '50%',
      flex: '0 0 auto',
      paddingRight: theme.spacing(4),
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        width: '43.5%',
      },
    },
  },
  rowSmall: {
    '& $imageWrapper': {
      [theme.breakpoints.down('sm')]: {
        width: '33%',
      },
    },
  },
  imageWrapper: {
    marginBottom: theme.spacing(5),
  },
  imageInner: {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      paddingBottom: (props) =>
        props.imageRatio === 'lsSmall'
          ? '46.6%'
          : props.imageRatio === 'lsMedium'
          ? '57.8%'
          : props.imageRatio === 'lsLarge'
          ? '71.6%'
          : '100%',
    },
  },
  image: {
    transitionProperty: 'all',
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    position: 'absolute !important',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    boxShadow: theme.customShadow.topicTeaser,
  },
  subline: {
    marginBottom: theme.spacing(2),
    color: theme.palette.red.main,
  },
  headline: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(2),
  },
  hlSmall: {
    ...theme.typography.h5,
  },
  copy: {
    ...theme.typography.teaser,
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.spacing(44),
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    ...theme.typography.articleMeta,
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  externalIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
}))

function MagazineTeaser({
  className,
  slug,
  image,
  imageAlt,
  subline,
  headline,
  copy,
  readingTime,
  createdAt,
  type,
  imageRatio,
  focalPoint,
  url,
}) {
  const classes = useStyles({ imageRatio, type })
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const focalPoints = focalPointsTable

  const { formatDate } = useDateFns()

  function isURLExternal(url) {
    if (!!!url) {
      return false
    }
    return url.match(/^((http[s]?|ftp|mailto|tel|fax|javascript):)/)
  }

  function TeaserLinkWrapper({ children }) {
    return (
      <>
        {isURLExternal(url) ? (
          <a
            data-track-content
            data-tracking-id={slug}
            className={clsx(className, classes.magazineTeaserRoot, {
              [classes.row]: type === 'row' || type === 'rowSmall',
              [classes.rowSmall]: type === 'rowSmall',
            })}
            target="_blank"
            rel="noopener noreferrer"
            href={slug}
          >
            {children}
          </a>
        ) : (
          <Link
            data-track-content
            data-tracking-id={slug}
            className={clsx(className, classes.magazineTeaserRoot, {
              [classes.row]: type === 'row' || type === 'rowSmall',
              [classes.rowSmall]: type === 'rowSmall',
            })}
            to={slug}
          >
            {children}
          </Link>
        )}
      </>
    )
  }

  return (
    <TeaserLinkWrapper>
      {image && (
        <div className={classes.imageWrapper}>
          <div className={classes.imageInner}>
            <Img
              className={classes.image}
              fluid={image}
              backgroundColor={true}
              alt={imageAlt}
              objectPosition={
                focalPoint ? focalPoints[focalPoint] : focalPoints['Center']
              }
            />
          </div>
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.subline}>{subline}</div>

        <Headline
          level={2}
          className={clsx(classes.headline, {
            [classes.hlSmall]: type === 'row' || type === 'rowSmall',
          })}
        >
          {headline}
          {isURLExternal(url) && (
            <Icon
              className={classes.externalIcon}
              name={'TextExternalArrow'}
              size="inherit"
            />
          )}
        </Headline>

        {copy && (
          <Copy
            className={classes.copy}
            html={copy}
            truncate={type === 'col' ? null : isLarge ? 30 : 25}
          />
        )}
        {(readingTime || createdAt) && (
          <div className={classes.meta}>
            {createdAt && <span>{formatDate(createdAt, 'd. MMMM')}</span>}
            {readingTime && (
              <span>
                <FormattedMessage
                  id="magazine.readingTime"
                  values={{ time: readingTime }}
                />
              </span>
            )}
          </div>
        )}
      </div>
    </TeaserLinkWrapper>
  )
}

MagazineTeaser.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  subline: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
  readingTime: PropTypes.number,
  createdAt: PropTypes.string,
  type: PropTypes.oneOf(['row', 'rowSmall', 'col']),
  imageRatio: PropTypes.oneOf([
    'sqSmall',
    'square',
    'lsSmall',
    'lsMedium',
    'lsLarge',
  ]),
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
}

MagazineTeaser.defaultProps = {
  type: 'row',
  imageRatio: 'square',
}

export default MagazineTeaser
